import * as S from './styles'

import Image from 'next/image'

import { useFetchAndVerifyAcessoCidadao } from '@/context/authContext'

import Logomonitora from '../../../public/svg/logo-processos.svg'

export function HomeScreen() {
  useFetchAndVerifyAcessoCidadao()
  return (
    <S.ContainerHome>
      <S.WrapperText>
        <Image src={Logomonitora} alt="" width={80} className="image-logo" />
        <div className="typewriter">
          <h1>Portfólio de Processos</h1>
          <br />
          <span>Verificando permissões de acesso</span>
        </div>
      </S.WrapperText>
    </S.ContainerHome>
  )
}
