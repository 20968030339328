import styled, { keyframes } from 'styled-components'

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.005);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`
export const ContainerHome = styled.div`
  user-select: none;

  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.black};
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const WrapperText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  .typewriter {
    > h1 {
      overflow: hidden;
      white-space: nowrap;
      margin: 0 auto;
      letter-spacing: 0.15em;

      font-family: ${({ theme }) => theme.font.primary};
      color: ${({ theme }) => theme.colors.white};
      font-size: ${({ theme }) => theme.font.sizes.xlarge};
      font-weight: ${({ theme }) => theme.font.bold};
    }
    > span {
      overflow: hidden;
      white-space: nowrap;
      margin: 0 auto;
      letter-spacing: 0.15em;

      font-family: ${({ theme }) => theme.font.primary};
      color: ${({ theme }) => theme.colors.white};
      font-size: ${({ theme }) => theme.font.sizes.xsmall};
      font-weight: ${({ theme }) => theme.font.bold};

      animation: ${pulseAnimation} 2s ease-in-out infinite;
    }
  }
`
