'use client'

import { HomeScreen } from '@/components/HomeScreen'

import { Suspense } from 'react'

export default function Home() {
  return (
    <Suspense>
      <HomeScreen />
    </Suspense>
  )
}
